









































































import Vue from "vue";
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";

import { Component, Prop, VModel, Watch } from "vue-property-decorator";

import { IDates } from "@/interfaces/common";

@Component({
  components: {
    DateRangePicker,
  },
  filters: {
    date(data) {
      if (data) {
        // "es" corresponde a dd/mm/yyyy
        const dateFormatter = Intl.DateTimeFormat("es");
        return dateFormatter.format(data);
      } else {
        return "select date";
      }
    },
  },
})
export default class ReservationDatesField extends Vue {
  @VModel({
    type: Object,
    default: () => ({
      Begin: null,
      End: null,
    }),
  })
  dates!: IDates;
  @Prop({
    type: Boolean,
    default: () => false,
  })
  readonly hideDetails!: boolean;
  @Prop({
    type: Object,
    default: () => null,
  })
  readonly days!: { [day: string]: any };
  @Prop({
    type: String,
    default: () => moment().add(1, "day").toISOString(),
  })
  readonly minDate!: string;

  @Prop({
    type: String,
    default: () => "left",
  })
  readonly opens!: string;

  get datesText() {
    return this.datesArr.map((x) => moment(x).format("L")).join("-");
  }

  datesArr = new Array<string>();
  dialog = false;
  sectionDay = "arrival";
  limitDay = "";

  @Watch("dates", { immediate: true })
  watchDates(val: IDates) {
    this.selectedDatesRange.startDate = moment(val.Begin).toISOString();
    this.selectedDatesRange.endDate = moment(val.End).toISOString();
  }

  protected cancel() {
    // Restauramos el valor
    this.dialog = false;
    this.datesArr = [];
    if (!this.dates.Begin) return;
    this.datesArr.push(this.dates.Begin);
    if (!this.dates.End) return;
    this.datesArr.push(this.dates.End);
  }
  protected save() {
    this.dialog = false;
    this.$emit("input", {
      Begin: this.datesArr.length > 0 ? this.datesArr[0] : null,
      End: this.datesArr.length > 1 ? this.datesArr[1] : null,
    });
  }

  protected validateDates(val: string[]) {
    // Validamos solo la salida
    if (val.length == 2) {
      // Si son iguales, no hacemos nada
      if (val[0] == val[1]) {
        return;
      }
      // Si esta antes, tomamos como primera la salida
      if (moment(val[0]).isAfter(val[1])) {
        this.datesArr = [val[1]];
        return;
      }
      const departure = this._validateDeparture({ from: val[0], to: val[1] });
      // Si son distintos, entonces comenzamos desde el ultimo seleccionado
      if (departure != val[1]) {
        this.datesArr = [val[1]];
        return;
      }
    }
    // En el caso de que la fecha de salida se seleccione como primera, validamos que este disponible
    else if (
      val.length == 1 &&
      this.datesArr.length == 2 &&
      val[0] == this.datesArr[1]
    ) {
      if (!this.allowedDates(val[0], "arrival", true)) return;
    }
    this.datesArr = val;
  }

  protected validateRange(val: { startDate; endDate }) {
    // Si son iguales, no hacemos nada
    if (val.endDate == val.startDate) {
      this.selectedDatesRange.startDate = null;
      this.selectedDatesRange.endDate = null;
      return;
    }
    // Si esta antes, tomamos como primera la salida
    if (moment(val.startDate).isAfter(val.endDate)) {
      this.selectedDatesRange.startDate = val.startDate;
      return;
    }
    const departure = this._validateDeparture({
      from: val.startDate,
      to: val.endDate,
    });
    // Si son distintos, entonces comenzamos desde el ultimo seleccionado
    if (departure != val.endDate) {
      this.selectedDatesRange.startDate = val.endDate;
      this.dates.Begin = val.endDate;
      this.dates.End = null;
      this.selectedDatesRange.endDate = null;
      return;
    }
    this.dates.Begin = this.selectedDatesRange.startDate;
    this.dates.End = this.selectedDatesRange.endDate;
    this.selectedDatesRange = val;
  }

  /***
   * Restringe las fechas que se pueden seleccionar
   **/
  protected allowedDates(dayName: string, section: "arrival" | "departure") {
    // Si no existe el dia mapeado, es que no tiene precio, por lo tanto es precio a consultar
    if (!this.days[dayName]) {
      return true;
    }
    // Si no tiene ni arribo ni salida, entonces es un dia disponible
    else if (
      !this.days[dayName]["arrival"] ||
      !this.days[dayName]["departure"]
    ) {
      return true;
    }
    // Si el arribo o salida es on demand periods, entonces se puede seleccionar
    else if (this.days[dayName][section].type == "onDemandPeriods") {
      return true;
    }
    // Si el arribo o salida es on changeDay, entonces se puede seleccionar
    else if (
      this.days[dayName][section].type == "changeDay" &&
      this.days[dayName].changeDay == moment(dayName).day()
    ) {
      return true;
    }

    return false;
  }

  notSelecteables(dayName: string) {
    if (
      this.days[dayName] &&
      this.days[dayName]["arrival"]?.type == "changeDay" &&
      this.days[dayName].changeDay != moment(dayName).day()
    ) {
      return true;
    }
  }

  /**
   * Valida la fechas seleccionadas considerando que en el medio no haya ninguna fecha no disponible
   * @returns Fecha de salida o de corte
   */
  private _validateDeparture(params: { from: string; to: string }) {
    const { from, to } = params;
    const beginDate = moment(from);
    const endDate = moment(to);
    const daysDiff = endDate.diff(beginDate, "day");
    for (let i = 0; i < daysDiff; i++) {
      const date = beginDate.clone().add(i, "day");
      const dateName = date.toISOString().substring(0, 10);
      if (this.days[dateName]?.arrival?.type == "reservation") {
        return dateName;
      }
    }
    return to;
  }

  selectedDatesRange: { startDate: string | null; endDate: string | null } = {
    startDate: null,
    endDate: null,
  };
  maxDate = "2022-12-26 14:00:00";
  timePicker = false;
  timePicker24Hour = false;
  showWeekNumbers = false;
  showDropdowns = false;
  autoApply = false;
  linkedCalendars = false;

  updateValues() {
    if (this.selectedDatesRange.startDate != null) {
      this.dates.Begin = moment(this.selectedDatesRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.dates.End = moment(this.selectedDatesRange.endDate).format(
        "YYYY-MM-DD"
      );
    }
    this.$emit("input", { Begin: this.dates.Begin, End: this.dates.End });
  }

  selection(dates) {
    if (this.days) this.validateRange(dates);
  }

  clearDates() {
    this.selectedDatesRange.startDate = null;
    this.selectedDatesRange.endDate = null;
    this.dates.Begin = null;
    this.dates.End = null;
    this.updateValues();
  }

  dateFormat(classes, date) {
    let dayName = moment(date).format("YYYY-MM-DD");
    if (!classes.disabled && this.days != null) {
      classes.disabled = !this.allowedDates(dayName, "arrival");
      classes.notSelectable = this.notSelecteables(dayName);
    }
    return classes;
  }

  get opensDirection() {
    if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
      return "center";
    }
    return this.opens;
  }
}
