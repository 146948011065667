







































































































import { routerStore } from "@/store/modules/router";
import { IMapDays } from "@/views/AccoDetail.vue";
import moment from "moment";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ILocalGuests } from "@/interfaces/common";
import CustomModal from "@/components/CustomModal.vue";
import { IPriceBreakDown } from "@/interfaces/accommodation";

@Component({
  components: {
    CustomModal,
  },
})
export default class BookingPriceDialog extends Vue {
  @Prop({
    type: Number,
  })
  readonly accommodationId!: number | null;
  @Prop({
    type: String,
  })
  readonly accommodationName!: string | null;
  @Prop({
    type: Object,
    default: () => ({}),
  })
  readonly days!: IMapDays;
  @Prop({
    type: Number,
    default: 0,
  })
  readonly totalRental!: number;
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly calculatingPrice!: boolean;

  dialog = false;
  guestsT = 0;
  guests: ILocalGuests = { GuestAdults: 1, GuestChildrens: 0, GuestInfants: 0 };
  begin = "";
  end = "";
  wrongDatesMsg = "";
  nights = 0;
  isQuote = false;
  priceBreakDown?: IPriceBreakDown | null = null;

  async openDialog({
    begin = "",
    end = "",
    totalGuests = 1,
    guests = { GuestAdults: 1, GuestChildrens: 0, GuestInfants: 0 },
    priceBreakDown = null,
    type = "",
  }) {
    // Asignamos el nuevo estado de las variables
    this.guestsT = totalGuests;
    this.guests = guests;
    this.begin = begin;
    this.end = end;
    this.nights = moment(end).diff(begin, "day");
    this.dialog = true;
    this.wrongDatesMsg = "";
    this.priceBreakDown = priceBreakDown;
    // Verificamos que el dia de cambio sea el correcto
    if (this.days[end]?.changeDay) {
      const endDate = moment(end);
      if (this.days[end].changeDay != endDate.isoWeekday()) {
        this.wrongDatesMsg = this.$t("detail.prices.wrongDatesChangeOverDay", {
          accommodationName: this.accommodationName,
          dayName: endDate
            .add(this.days[end]?.changeDay ?? 0 - endDate.isoWeekday(), "day")
            .format("dddd"),
        }).toString();
        return;
      }
    }
    // Consultamos si los dias tienen precios
    if (!this.days[end]?.price) {
      this.wrongDatesMsg = this.$t("detail.prices.noPricing").toString();
      return;
    }
    // Consultamos los precios si cumplen con la estancia minima
    if ((this.days[begin]?.minimumStay ?? 0) > this.nights) {
      this.wrongDatesMsg = this.$t("detail.prices.wrongDates").toString();
      return;
    }

    // Si tiene precio pero es onDemand se debe enviar formulario de presupuesto
    if (
      this.days[end].departure?.type == "onDemandPeriods" ||
      this.days[begin].departure?.type == "onDemandPeriods"
    ) {
      this.isQuote = true;
    } else {
      this.isQuote = false;
    }
  }

  closeDialog() {
    // Reseteamos los valores
    this.guestsT = 0;
    this.begin = "";
    this.end = "";
    this.nights = 0;
    this.dialog = false;
  }

  goToBooking(params: { isQuote: boolean | null }) {
    routerStore.setRoute({
      name: "BookingProcess",
      props: {
        accommodationId: this.accommodationId,
        arrival: this.begin,
        departure: this.end,
        guests: this.guests,
        isQuote: params?.isQuote,
      },
    });
  }
  goToQuote() {
    this.goToBooking({ isQuote: true });
  }
}
