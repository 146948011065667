





























































































































































































































































import Vue from "vue";
import { Component, Prop, PropSync, VModel } from "vue-property-decorator";
import VueI18n from "vue-i18n";

import { IMapDays } from "@/views/AccoDetail.vue";
import { IAccommodation } from "@/interfaces/accommodation";

import AccoMap from "@/components/accoDetail/AccoMap.vue";
import BookingCalendar from "@/components/accoDetail/BookingCalendar.vue";
import Prices from "@/components/accoDetail/Prices.vue";
import Reviews from "@/components/accoDetail/Reviews.vue";
import ContactForm from "@/components/accoDetail/ContactForm.vue";
import { IDates, ILocalGuests } from "@/interfaces/common";
import moment from "moment";
import { routerStore } from "@/store/modules/router";
import { PARKING_TYPE } from "@/data";
import { authStore } from "@/store/modules/auth";

export interface ISection {
  value: string;
  icon: string;
  t: () => VueI18n.TranslateResult;
  disabled: boolean;
}

@Component({
  components: {
    AccoMap,
    BookingCalendar,
    Prices,
    ContactForm,
    Reviews,
  },
})
export default class DetailBody extends Vue {
  readMoreActivated = false;

  @Prop({
    type: String,
    default: "normal",
  })
  readonly mode!: "normal" | "brochure";
  @Prop({
    type: Object,
    required: true,
  })
  readonly accommodation!: IAccommodation;
  @Prop({
    type: Number,
    required: true,
  })
  readonly bodyOffset!: number;
  @Prop({
    type: Object,
  })
  readonly days!: IMapDays;
  @Prop({
    type: Array,
    default: () => [],
  })
  readonly sections!: ISection[];
  @Prop({
    type: Object,
  })
  readonly dates!: IDates | null;
  @Prop({
    type: Object,
  })
  readonly guests!: ILocalGuests;
  @Prop({
    type: String,
    default: "720px",
  })
  readonly brochureWidth!: string;

  @PropSync("dates", { type: Object }) syncedDates!: IDates;

  @VModel({
    type: Number,
    default: -1,
  })
  pageSection!: number;

  get bedroomsCount() {
    return (
      this.accommodation?.Floors?.map(
        (floor) => floor.BedRooms?.length ?? 0
      ).reduce((a, b) => a + b, 0) ?? 0
    );
  }

  get bathroomsCount() {
    return (
      this.accommodation?.Floors?.map(
        (floor) => floor.BathRooms?.length ?? 0
      ).reduce((a, b) => a + b, 0) ?? 0
    );
  }

  get filteredFacilities() {
    const facilities = new Array<{
      text: string;
      value: number | boolean;
      type: string;
      group: string;
    }>();
    const Services = this.accommodation?.Services;
    const CommunalFacilities = this.accommodation?.CommunalFacilities;
    const IndoorFacilities = this.accommodation?.IndoorFacilities;
    const OutdoorFacilities = this.accommodation?.OutdoorFacilities;
    const Distances = this.accommodation?.Distances;

    for (const s in Services) {
      switch (typeof Services[s]) {
        case "number":
          if (Services[s] > 0) {
            facilities.push({
              text: s,
              value: Services[s],
              type: typeof Services[s],
              group: "services",
            });
          }
          break;
        case "boolean":
          if (Services[s]) {
            facilities.push({
              text: s,
              value: Services[s],
              type: typeof Services[s],
              group: "services",
            });
          }
          break;
      }
    }

    for (const cf in CommunalFacilities) {
      switch (typeof CommunalFacilities[cf]) {
        case "number":
          if (
            !["PoolDepth", "PoolLength", "PoolShape", "PoolWidth"].includes(
              cf
            ) &&
            CommunalFacilities[cf] > 0
          ) {
            facilities.push({
              text: cf,
              value: CommunalFacilities[cf],
              type: typeof CommunalFacilities[cf],
              group: "communal",
            });
          }
          break;
        case "boolean":
          if (CommunalFacilities[cf]) {
            facilities.push({
              text: cf,
              value: CommunalFacilities[cf],
              type: typeof CommunalFacilities[cf],
              group: "communal",
            });
          }
          break;
      }
    }

    for (const infac in IndoorFacilities) {
      switch (typeof IndoorFacilities[infac]) {
        case "number":
          if (
            !["TotalAirConditioning"].includes(infac) &&
            IndoorFacilities[infac] > 0
          ) {
            facilities.push({
              text: infac,
              value: IndoorFacilities[infac],
              type: typeof IndoorFacilities[infac],
              group: "indoor",
            });
          }
          break;
        case "boolean":
          if (IndoorFacilities[infac]) {
            facilities.push({
              text: infac,
              value: IndoorFacilities[infac],
              type: typeof IndoorFacilities[infac],
              group: "indoor",
            });
          }
          break;
      }
    }

    for (const outfac in OutdoorFacilities) {
      switch (typeof OutdoorFacilities[outfac]) {
        case "number":
          if (OutdoorFacilities[outfac] > 0) {
            facilities.push({
              text: outfac,
              value: Number.isSafeInteger(OutdoorFacilities[outfac])
                ? OutdoorFacilities[outfac]
                : OutdoorFacilities[outfac].toFixed(2),
              type: typeof OutdoorFacilities[outfac],
              group: "outdoor",
            });
          }
          break;
        case "boolean":
          if (OutdoorFacilities[outfac]) {
            facilities.push({
              text: outfac,
              value: OutdoorFacilities[outfac],
              type: typeof OutdoorFacilities[outfac],
              group: "outdoor",
            });
          }
          break;
      }
    }

    for (const distance in Distances) {
      if (Distances[distance].Distance > 0) {
        facilities.push({
          text: distance,
          value: Distances[distance].Distance,
          type: "distance",
          group: "distances",
        });
      }
    }

    return facilities.sort((a, b) => a.text.localeCompare(b.text));
  }

  get gallery() {
    if (this.accommodation?.Pictures) {
      return this.accommodation.Pictures.map(
        (element) =>
          "https://duzf08k2n1y1n.cloudfront.net/" +
          element.StandardVersion.S3Key.split("/")[1]
      );
    } else return [];
  }

  get datesSelected() {
    return this.dates?.Begin && this.dates?.End
      ? moment(this.dates.Begin).format("L") +
          " - " +
          moment(this.dates.End).format("L")
      : "";
  }

  get totalRental() {
    return this.accommodation.TotalRental ?? 0;
  }

  get totalGuests() {
    const result = this.guests ?? {
      GuestAdults: this.accommodation.MaxGuests,
      GuestChildrens: 0,
      GuestInfants: 0,
    };
    return result.GuestAdults + result.GuestChildrens + result.GuestInfants;
  }
  irentElementOffset = 0;
  protected mounted() {
    this.irentElementOffset = this.$common.getTop(
      document.querySelector("irent-script") as HTMLElement
    );
    window.addEventListener("scroll", this._handleScroll);
    if (routerStore.routeProps.scrollTo) {
      setTimeout(() => {
        this.scrollTo(routerStore.routeProps.scrollTo);
      }, 10);
    }
  }

  protected beforeDestroy() {
    window.removeEventListener("scroll", this._handleScroll);
  }

  private _handleScroll() {
    let minDistance = 50;
    let minIndex = this.pageSection;
    for (let i = 0; i < this.sections.length; i++) {
      const element = this.$refs[
        `detail-${this.sections[i].value}`
      ] as HTMLDivElement;
      if (element) {
        const position = element.getBoundingClientRect();
        if (position.top > 0 && position.top < window.innerHeight / 2) {
          // Calculamos la distancia desde el centro
          const distance = Math.abs(position.top / window.innerHeight - 0.5);
          if (distance < minDistance) {
            minDistance = distance;
            minIndex = i;
          }
        }
        // Puede pasar que esta abarcando toda la pantalla
        else if (position.top < 0 && position.bottom > window.innerHeight) {
          minIndex = i;
          break;
        }
      }
    }
    if (minIndex != this.pageSection) this.pageSection = minIndex;
  }

  /***
   * Scrollea a la seccion especificada
   * @param val - indica el indice de la seccion
   * @param offset - indica cuanto mas scrollear para que quede bien posicionado
   * ***/
  public scrollToSection(val: number, offset = 0) {
    const element = this.$refs[
      `detail-${this.sections[val].value}`
    ] as HTMLDivElement;
    if (!element) return;
    const top = element.offsetTop + offset;
    this.$vuetify.goTo(this.irentElementOffset + top - 45, {
      duration: 1000,
      offset: 0,
      easing: "easeOutQuint",
    });
  }

  /***
   * Scrollea a la elemento especificado
   * @param val - indica el nombre de referencia (ref) del elemnto
   * @param offset - indica cuanto mas scrollear para que quede bien posicionado
   * ***/
  public scrollTo(val: string, offset = 0) {
    const element = this.$refs[val] as HTMLDivElement;
    if (!element) return;
    const top = element.offsetTop + (offset ? offset : this.bodyOffset);
    this.$vuetify.goTo(this.irentElementOffset + top, {
      duration: 500,
      offset: 0,
      easing: "easeOutQuint",
    });
  }

  activateReadMore() {
    this.readMoreActivated = !this.readMoreActivated;
    //Si es 'read less' scrolleamos para que continue en el mismo lugar
    if (!this.readMoreActivated) {
      setTimeout(() => {
        this.scrollTo("readMoreButton", -250);
      }, 50);
    }
  }

  facilityNameAndDescription(facility: any) {
    let resp = "";
    if (facility.text.includes("ParkingType")) {
      let typeOrder = facility.text.charAt(facility.text.length - 1);
      // Pendiente si se quiere poner la cantidad de estacionapientos ParkingQuantity{typeorder}
      resp = this.$t(
        `detail.facilities.${PARKING_TYPE[facility.value]}`
      ).toString();
    } else {
      resp =
        this.$options.filters?.capitalize(
          this.$t(`detail.facilities.${facility.text}`)
        ) +
        " " +
        this.$common.facilitiesDetails(facility);
    }
    return resp;
    //  !facility.text.includes("Parking")
    //   ? $t(`detail.facilities.${facility.text}`)
    //   : $t(`detail.facilities.${parkingTypes[facility.value]}`) | capitalize;
  }

  get accoDescription() {
    if (
      this.accommodation.Descriptions.Long &&
      this.accommodation.Administrator.Id == authStore.administrator?.Agent.Id
    ) {
      return this.accommodation.Descriptions.Long;
    } else {
      return this.accommodation.Descriptions.AutoLong;
    }
  }
}
