import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import jwt_decode from "jwt-decode";
import moment from "moment";

import i18n from "@/plugins/i18n";
import store from "@/store";

import { irentAPI } from "@/services/api";

import { IUser } from "@/interfaces/user";
import {
  IAccommodationType,
  ILocalLocation,
  ILocations,
} from "@/interfaces/common";

export type AuthStatusType = "none" | "success" | "failure";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "auth",
})
export class AuthStore extends VuexModule {
  websiteCode = "";
  agentKey = "";
  tokenAdministrator = "";
  payloadTokenAdministrator: { [x: string]: any } = {};
  administrator: IUser | null = null;
  currency = "";
  language = "";
  parentUrl: string | null = "";
  authStatus: AuthStatusType = "none";
  locations: {
    text: string;
    value: ILocalLocation;
  }[] = [];
  accommodationTypes: IAccommodationType[] = [];
  winter = false;
  sales = false;

  get websiteId(): number {
    return this.payloadTokenAdministrator
      ? JSON.parse(this.payloadTokenAdministrator.websiteId)
      : -1;
  }

  @Mutation
  setTokenAdministrator(payload: string) {
    this.tokenAdministrator = payload;
    if (payload) {
      irentAPI.defaults.headers.common["Authorization"] = "Bearer " + payload;
      this.payloadTokenAdministrator = jwt_decode(payload);
    } else {
      delete irentAPI.defaults.headers.common["Authorization"];
      this.payloadTokenAdministrator = {};
    }
  }

  @Mutation
  setWebsiteCode(payload: string) {
    this.websiteCode = payload;
  }

  @Mutation
  setAgentKey(payload: string) {
    this.agentKey = payload;
  }

  @Mutation
  setAdministrator(payload: IUser) {
    this.administrator = payload;
  }

  @Mutation
  setCurrency(payload: string) {
    this.currency = payload;
  }

  @Mutation
  setWinter(payload: boolean) {
    this.winter = payload;
  }

  @Mutation
  setSales(payload: boolean) {
    this.sales = payload;
  }

  @Mutation
  setLanguage(payload: string) {
    this.language = payload;
    i18n.locale = payload;
    moment.locale(payload);
  }

  @Mutation
  setParentUrl(payload: string | null) {
    if (payload) {
      this.parentUrl = payload;
    } else {
      this.parentUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    }
  }

  @Mutation
  setAuthStatus(payload: AuthStatusType) {
    this.authStatus = payload;
  }

  @Mutation
  setLocations(payload: ILocations) {
    if (!payload.Countries) return;
    // this.locations = payload.Countries.reduce((prev, current) => {

    // }, [])
    this.locations = [];
    for (const country of payload.Countries) {
      this.locations.push({
        text: country.Name,
        value: { LocationId: country.Id, LevelId: "Country" },
      });

      for (const area of payload.Areas) {
        if (area.ParentId != country.Id) continue;
        this.locations.push({
          text: country.Name + ", " + area.Name,
          value: { LocationId: area.Id, LevelId: "Area" },
        });

        for (const city of payload.Cities) {
          if (city.ParentId != area.Id) continue;
          this.locations.push({
            text: country.Name + ", " + area.Name + ", " + city.Name,
            value: { LocationId: city.Id, LevelId: "City" },
          });
        }
      }
    }
  }

  @Mutation
  setAccommodationTypes(payload: IAccommodationType[]) {
    this.accommodationTypes = payload;
  }

  @Action
  async checkWebsiteCode(code: string) {
    try {
      this.context.commit("setWebsiteCode", code);
      const token = await irentAPI.post<string>("/security/auth-website", {
        WebsiteCode: code,
      });
      await this.checkTokenAdministrator(token.data);
    } catch (err) {
      console.warn("[POST][AUTH_WEBSITE]: ", err);
      this.context.commit("setWebsiteCode", "");
      this.context.commit("setAuthStatus", "failure");
    }
  }

  @Action
  async checkTokenAdministrator(token: string) {
    try {
      // Obtenemos el websiteId del payload del token
      const websiteId = JSON.parse(jwt_decode<any>(token).websiteId);
      // Obtenemos la informacion del token obtenido
      const resUser = await irentAPI.get<IUser>("/v1/User/UserInfo", {
        headers: { Authorization: "Bearer " + token },
      });
      // Obtenemos las locaciones a tratar en el website
      const resLocation = await irentAPI.get<ILocations>(
        "/v1/Website/Locations",
        {
          params: {
            WebsiteId: websiteId,
            Language: this.language,
          },
          headers: { Authorization: "Bearer " + token },
        }
      );
      // Obtenemos los tipos de alojamientos
      const resAccommodationTypes = await irentAPI.get<IAccommodationType>(
        "/v1/Website/AccommodationTypes",
        {
          params: {
            WebsiteId: websiteId,
            Language: this.language,
          },
          headers: { Authorization: "Bearer " + token },
        }
      );

      this.context.commit("setTokenAdministrator", token);
      this.context.commit("setAdministrator", resUser.data);
      this.context.commit("setLocations", resLocation.data);
      this.context.commit("setAccommodationTypes", resAccommodationTypes.data);
      this.context.commit("setAuthStatus", "success");
    } catch (err) {
      console.warn("[GET][USERINFO]: ", err);
      this.context.commit("setTokenAdministrator", "");
      this.context.commit("setAuthStatus", "failure");
    }
  }
}

export const authStore = getModule(AuthStore);
