










































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { ILocalExtra } from "@/views/BookingProcess.vue";

@Component
export default class OptionalExtras extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly value!: Array<ILocalExtra>;
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly flat!: boolean;

  get fieldInfo() {
    const res: {
      [x: number]: {
        type: string;
        items?: Array<{ text: string; value: number }>;
      };
    } = {};
    for (let i = 0; i < this.value.length; i++) {
      const extra = this.value[i].Extra;
      const maxQuantity = extra.MaxQuantity ?? 1;
      if (maxQuantity > 1) {
        res[i] = {
          type: "select",
          items: Array(maxQuantity + 1)
            .fill(null)
            .map((_, i) => ({
              text: i.toString(),
              value: i,
            })),
        };
      } else {
        res[i] = {
          type: "checkbox",
        };
      }
    }
    return res;
  }
}
