import Vue from "vue";
import moment from "moment";

import { authStore } from "@/store/modules/auth";
import { routerStore } from "@/store/modules/router";
import { ICON_LIST, LANGUAGES, POOL_SHAPE } from "@/data";

import { ILanguageNames } from "@/interfaces/common";

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const to = (routeName: string, props?: { [x: string]: any }) => {
  routerStore.setRoute({ name: routeName, props });
};

const toLangName = (names: ILanguageNames) =>
  names[LANGUAGES[authStore.language]] ?? names["English"];

const toLang = (lang?: string) =>
  LANGUAGES[lang ?? authStore.language] ?? "English";

const facilitiesDetails = (facility: {
  text: string;
  value: number;
  type: string;
}) => {
  if (facility.text === "PoolShape") {
    return POOL_SHAPE[facility.value];
  } else if (
    facility.text === "PoolLength" ||
    facility.text === "PoolDepth" ||
    facility.text === "PoolDepth" ||
    facility.text === "PoolShape"
  ) {
    return facility.value + " mts.";
  } else if (facility.type === "distance") {
    return facility.value / 1000 + " km.";
  } else {
    return "";
  }
};

const winter = () => {
  return authStore.winter;
};

const winterPrice = (weekPrice) => {
  return (weekPrice / 7) * 30;
};

/**
 * Reemplaza los espacios por guiones
 */
const encodeParameter = (str = "") => {
  return str.replaceAll(" ", "-").toLowerCase();
};
/**
 * Reemplaza los guiones por espacios
 * @param {*} s String a decodificar
 */
const decodeParameter = (str = "") => {
  return str.replaceAll("---", "-_-").replaceAll("-", " ").replaceAll("_", "-");
};

const dateFormat = (value: any, format = "DD/MM/yyyy") => {
  if (typeof value != "string" && !(value instanceof Date)) {
    return value;
  }
  return moment(value).format(format);
};

const iconFromList = (value: string) => ICON_LIST[value];

const getTop = (el: HTMLElement) =>
  el.offsetTop + (el.offsetParent && getTop(el.offsetParent as HTMLElement));
/**
 * Abre un cuadro de dialogo con el proceso de pago
 */
const openProcessPayment = (params: {
  code: number;
  reservationNumber: string;
  amount: number;
  fee: number;
}) => {
  const { code, reservationNumber, amount, fee } = params;
  const langMap: { [x: string]: string } = {
    nl: "nl",
    de: "d",
    en: "gb",
    fr: "fr",
    es: "esp",
  };
  const websitekey = authStore.websiteCode; // "BD96C811-B2CC-4DA2-B6C4-696C014B481E"
  const agentkey = authStore.agentKey; // "B74C3F02-DAAD-4190-A594-7FE0F89FA3F8"
  const lang = langMap[authStore.language];
  const amountFormatted = amount.toFixed(2).replaceAll(".", ",");
  // const code = "C7FB640B-4875-4D96-AD8E-F01D7C7B5063";
  // const reservationNumber = "2022-16159";
  // const amount = 81.9;
  // const fee = 0;
  window.open(
    `https://booking.i-rent.net/booking_system/tpv/tpv.aspx?websitekey=${websitekey}&agentkey=${agentkey}&code=${code}&lan=${lang}&number=${reservationNumber}&amount=${amountFormatted}&fee=${fee}&host=${authStore.parentUrl}`
  );
};

Vue.filter("capitalize", (value: any) => {
  if (typeof value != "string") {
    return value;
  }
  return capitalize(value);
});

Vue.filter("dateFormat", dateFormat);

Vue.filter("calendar", (value: any) => {
  if (typeof value != "string" && !(value instanceof Date)) {
    return value;
  }
  return moment(value).calendar({
    sameDay: "[Today]",
    lastDay: "[Yesterday]",
    nextDay: "[Tomorrow]",
    nextWeek: "L",
    lastWeek: "L",
    sameElse: "L",
  });
});

Vue.filter("currency", (value: any) => {
  let ammount = 0;
  if (typeof value == "string") ammount = parseFloat(value);
  else if (typeof value == "number") ammount = value;
  return new Intl.NumberFormat(authStore.language, {
    style: "currency",
    currency: authStore.currency,
  }).format(ammount);
});

Vue.filter("toLangName", (value: any) => {
  if (typeof value == "object") return toLangName(value);
  return value;
});

Vue.filter("iconFromList", (value: any) => {
  if (typeof value == "string") return iconFromList(value);
  return value;
});

Vue.filter("encodeParameter", (value: any) => {
  if (typeof value != "string") {
    return value;
  }
  return encodeParameter(value);
});

Vue.filter("decodeParameter", (value: any) => {
  if (typeof value != "string") {
    return value;
  }
  return decodeParameter(value);
});

const $common = {
  capitalize,
  to,
  facilitiesDetails,
  iconFromList,
  encodeParameter,
  decodeParameter,
  toLang,
  toLangName,
  dateFormat,
  openProcessPayment,
  winter,
  winterPrice,
  getTop,
};

Vue.prototype.$common = $common;

export default $common;
