






































































































































































































import { IExtra } from "@/interfaces/common";
import { IMapDays } from "@/views/AccoDetail.vue";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Prices extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  readonly days!: IMapDays;
  @Prop({
    type: Array,
    required: true,
  })
  readonly extras!: IExtra[];

  get allExtras() {
    // const result = new Array<IExtra>();
    // for (const extra of this.extras) {
    //   if (extra.Package != undefined) {
    //     result.push(...extra.Package);
    //   } else {
    //     result.push(extra);
    //   }
    const result = new Array<IExtra>();
    if (this.extras) {
      for (const extra of this.extras) {
        extra.TotExtAmount = extra.Amount;
        result.push(extra);
        //}
      }
    }

    // Ordenamos alfabeticamente
    result.sort((a, b) =>
      this.$common
        .toLangName(a.Description)
        .localeCompare(
          this.$common.toLangName(b.Description),
          this.$i18n.locale
        )
    );

    return result;
  }

  get extrasMandatoryFiltered() {
    const arr = this.allExtras
      .filter(
        (extra) =>
          extra.Mandatory &&
          !extra.PriceIncluded &&
          !(extra.Amount === 0 && extra.Type !== 100 && extra.Type !== 14)
      )
      .slice(0)
      .sort((a, b) =>
        this.$common
          .toLangName(a.Description)
          .localeCompare(
            this.$common.toLangName(b.Description),
            this.$i18n.locale
          )
      );
    return arr;
  }

  get extrasInPriceFiltered() {
    const arr = this.allExtras
      .filter((extra) => extra.PriceIncluded || extra.BookingIncluded)
      .slice(0)
      .sort((a, b) =>
        this.$common
          .toLangName(a.Description)
          .localeCompare(
            this.$common.toLangName(b.Description),
            this.$i18n.locale
          )
      );
    return arr;
  }

  get prices() {
    const prices = new Array<{ begin: string; end: string; price: number }>();
    const arr = Object.keys(this.days)
      .filter((k) => this.days[k].price != undefined)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    if (arr.length == 0) return [];
    // Agrgamos el comienzo del primer periodo
    prices.push({
      begin: arr[0],
      end: "",
      price: this.days[arr[0]].price ?? 0,
    });
    for (let i = 1; i < arr.length; i++) {
      // Si los precios son distintos, realizamos el corte de periodo
      if (this.days[arr[i]].price != this.days[arr[i - 1]].price) {
        // Completamos el periodo de precio
        prices[prices.length - 1].end = arr[i - 1];
        // Agregamos el comienzo de un nuevo periodo
        prices.push({
          begin: arr[i],
          end: "",
          price: this.days[arr[i]].price ?? 0,
        });
      }
      // Completamos el fin del ultimo periodo
      prices[prices.length - 1].end = arr[arr.length - 1];
    }
    return prices;
  }
}
