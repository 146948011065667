import { render, staticRenderFns } from "./OptionalExtras.vue?vue&type=template&id=4c64263f&scoped=true&"
import script from "./OptionalExtras.vue?vue&type=script&lang=ts&"
export * from "./OptionalExtras.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./OptionalExtras.vue?vue&type=style&index=0&id=4c64263f&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4c64263f",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VCardTitle,VSimpleCheckbox,VSimpleTable})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
