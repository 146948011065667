


















































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { Validate } from "vuelidate-property-decorators";
import { email, maxLength, required, sameAs } from "vuelidate/lib/validators";

import { authStore } from "@/store/modules/auth";
import { irentAPI } from "@/services/api";

import { IDates, ILocalGuests } from "@/interfaces/common";
import { IMapDays } from "@/views/AccoDetail.vue";

import ReservationDatesField from "@/components/ReservationDatesField.vue";
import GuestsField from "@/components/GuestsField.vue";

import CustomModal from "@/components/CustomModal.vue";

interface IContactFormBody {
  Language: string;
  Dates: IDates;
  GuestsField: ILocalGuests | null;
  Remarks: string;
  Email: string;
  Treatment: string;
  Name: string;
  SurName: string;
  Telephone: string;
  CellPhone: string;
  Address: string;
  PostalCode: string;
  City: string;
  Country: string;
  Newsletter: boolean;
  AccommodationId: number;
}

@Component({
  mixins: [validationMixin],
  components: {
    GuestsField,
    ReservationDatesField,
    CustomModal,
  },
  validations: {
    item: {
      comment: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile: {
        required,
      },
      disclaimer: {
        required,
        sameAs: sameAs(() => true),
      },
      surname: {
        required,
        maxLength,
      },
    },
  },
})
export default class ContactForm extends Vue {
  @Prop({
    type: Object,
    default: () => ({
      Begin: null,
      End: null,
    }),
  })
  readonly dates!: IDates;
  @Prop({
    type: Object,
    default: () => null,
  })
  readonly guests!: ILocalGuests | null;
  @Prop({
    type: Number,
    default: () => 100,
  })
  readonly maxGuests!: number;
  @Prop({
    type: Object,
    default: () => ({}),
  })
  readonly days!: IMapDays;
  @Prop({
    type: Number,
  })
  readonly accoId!: number;

  get lang() {
    return authStore.language;
  }

  item = {
    comment: "",
    address: "",
    mobile: "",
    disclaimer: false,
    surname: "",
    email: "",
  };
  privacyPolicyDialog = false;
  title = null;
  titles = new Array<{ value: string; text: string }>();
  selected = null;
  selectedCountry = null;
  firstName = "";
  phone_number = "";
  address = "";
  postalCode = "";
  city = "";
  country = "";
  countries = [
    {
      value: 203,
      text: "Argentina",
    },
    {
      value: 203,
      text: "España",
    },
    {
      value: 75,
      text: "Francia",
    },
  ];
  newsletter = true;
  modal = false;
  people = ["Adults: 0", "Children: 0", "Infants: 0"];
  submitDisabled = false;
  submitStatus = "";
  body: Partial<IContactFormBody> = {};

  protected async mounted() {
    this.titles = [
      {
        value: "mr",
        text: this.$t("reservation.mr").toString(),
      },
      {
        value: "mrs",
        text: this.$t("reservation.mrs").toString(),
      },
      {
        value: "family",
        text: this.$t("reservation.family").toString(),
      },
      {
        value: "company",
        text: this.$t("reservation.company").toString(),
      },
    ];
    let response = await irentAPI.get(
      `/v1/Website/CountriesList?Language=${this.lang}`
    );
    this.countries = response.data;
  }

  validateState(item: any) {
    const { $dirty, $error } = item;
    return $dirty ? !$error : null;
  }

  protected async submitContactForm() {
    this.body.Language = this.lang;
    this.body.Dates = { ...this.dates };
    this.body.GuestsField = this.guests ? { ...this.guests } : null;
    this.body.Remarks = this.item.comment;
    this.body.Email = this.item.email;
    this.body.Treatment = this.title ? this.title : "";
    this.body.Name = this.firstName;
    this.body.SurName = this.item.surname;
    this.body.Telephone = this.phone_number;
    this.body.CellPhone = this.item.mobile;
    this.body.Address = this.address;
    this.body.PostalCode = this.postalCode;
    this.body.City = this.city;
    this.body.Country = this.selectedCountry ? this.selectedCountry : "";
    this.body.Newsletter = this.newsletter;
    this.body.AccommodationId = this.accoId;
    // this.body.disclaimer = this.disclaimer;
    this.$v.item.$touch();
    if (this.$v.item.$anyError) {
      return false;
    } else {
      this.submitStatus = "PENDING";
      this.submitDisabled = true;
      let res = await this._sendContactForm(this.body);
      if (res == 200) {
        this.submitStatus = "OK";
      } else {
        this.submitStatus = "KO";
        this.submitDisabled = false;
      }
    }
  }

  protected requiredErrors(controlName: string) {
    const errors = new Array<string>();
    if (!this.$v[controlName].$dirty) return errors;
    !this.$v[controlName].required && errors.push("This field is required.");
    return errors;
  }

  protected emailErrors() {
    const errors = new Array<string>();
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push("Must be valid e-mail");
    !this.$v.email.required && errors.push("E-mail is required");
    return errors;
  }

  protected checkboxTermsErrors() {
    const errors = new Array<string>();
    if (!this.$v.disclaimer.$dirty) return errors;
    !this.$v.disclaimer.checked && errors.push("You must agree to continue!");
    return errors;
  }

  private async _sendContactForm(body: Partial<IContactFormBody>) {
    try {
      const res = await irentAPI.post("/v1/Website/ContactForm", {
        ...body,
        ...body.GuestsField,
        WebsiteId: authStore.websiteId,
      });
      return res.status;
    } catch (err) {
      console.warn("[ERROR][CONTACT_FORM]: ", err);
      return null;
    }
  }
}
