import { render, staticRenderFns } from "./CustomModal.vue?vue&type=template&id=28c6fe74&scoped=true&"
import script from "./CustomModal.vue?vue&type=script&lang=ts&"
export * from "./CustomModal.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./CustomModal.vue?vue&type=style&index=0&id=28c6fe74&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "28c6fe74",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VRow})
