import { render, staticRenderFns } from "./Reviews.vue?vue&type=template&id=4c44dabb&scoped=true&"
import script from "./Reviews.vue?vue&type=script&lang=ts&"
export * from "./Reviews.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./Reviews.vue?vue&type=style&index=0&id=4c44dabb&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4c44dabb",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VIcon,VProgressLinear,VRow})
